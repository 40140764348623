import React from 'react';
import { ThemeProvider } from 'react-fela';
import { ChakraProvider } from '@chakra-ui/react';
import { CookiesProvider } from 'react-cookie';
import { ConnectedRouter } from 'connected-react-router';

import customTheme from './customTheme';
import Main from './components/Main/Main';
import ToastComponent from './components/helpers/ToastComponent';
import { FelaProvider } from './fela/fela-fela-provider';
import { getFelaRenderer } from './fela/get-fela-renderer';
import { history } from './store/reducers';

import './App.css';
import './theming.css';

export default () => {
  const renderer = getFelaRenderer();

  return (
    <ConnectedRouter history={history}>
      <FelaProvider renderer={renderer}>
        <ThemeProvider theme={{}}>
          <ChakraProvider theme={customTheme}>
            <CookiesProvider>
              <Main />
            </CookiesProvider>
            <ToastComponent />
          </ChakraProvider>
        </ThemeProvider>
      </FelaProvider>
    </ConnectedRouter>
  );
};
