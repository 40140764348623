import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, useDisclosure } from '@chakra-ui/react';

import ApiConfig from 'src/api/ApiConfig';
import LoadingSpinner from '../helpers/LoadingSpinner';
import { closeFrame } from 'src/store/actions/frameActions';
import type { State } from 'src/types/state';

export default function FramePreview() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setLoading] = useState(true);
  const [contentHeight, setContentHeight] = useState(0);
  const frameState = useSelector((state: State) => state.frame);

  useEffect(() => {
    if (frameState.isOpen) {
      onOpen();
      setLoading(true);
    } else {
      onClose();
      setLoading(false);
    }
  }, [frameState.isOpen]);

  return (
    <Modal
      onClose={() => {
        dispatch(closeFrame());
        setLoading(false);
        onClose();
      }}
      size="full"
      isOpen={isOpen}
    >
      <ModalContent>
        <ModalHeader>{t('labels.ticket_preview')}</ModalHeader>
        <ModalCloseButton />

        <ModalBody ref={containerRef}>
          {isLoading && <LoadingSpinner />}

          <iframe
            scrolling="no"
            onLoad={() => {
              if (containerRef.current) {
                setLoading(false);
                setContentHeight(containerRef.current.clientHeight - 40);
              }
            }}
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            style={{ width: '100%', height: `${contentHeight}px` }}
            src={`${ApiConfig.getConfig().SUPPORT_FRONTEND}/case/${frameState.contentId}?hideNavigation=true`}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
