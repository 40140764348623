import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Alert, AlertIcon, Divider, Heading } from '@chakra-ui/react';

import BackendApi from 'src/api/BackendApi';
import Page from 'src/components/Page/Page';
import WidgetView from './WidgetView';
import LoadingSpinner from 'src/components/helpers/LoadingSpinner';
import type { State } from 'src/types/state';
import type { ViewConfig } from 'src/types/Config';

export default function EntityView() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const viewConfig = useSelector((state: State) => state.viewConfig) as ViewConfig;

  const [entity, setEntity] = useState<Record<string, unknown>>();
  const [entityTags, setEntityTags] = useState<number[]>([]);
  const [isEntityFetching, setEntityFetching] = useState(true);
  const [isEntityTagsFetching, setEntityTagsFetching] = useState(true);

  useEffect(() => {
    BackendApi.getEntityById({ entityId: id })
      .then((response) => setEntity(response))
      .finally(() => setEntityFetching(false));

    BackendApi.getEntityTags(id)
      .then((response) => setEntityTags(response))
      .finally(() => setEntityTagsFetching(false));
  }, []);

  if (isEntityFetching || isEntityTagsFetching) {
    return <LoadingSpinner />;
  }

  if (viewConfig.entityViews.find((entityView) => entityTags.includes(entityView.tag)) === undefined) {
    return (
      <Page>
        <Alert status="info" borderRadius={8}>
          <AlertIcon />
          {t('labels.no_provider_enabled')}
        </Alert>
      </Page>
    );
  }

  return (
    <Page>
      <Heading fontSize="3xl">{entity?.[viewConfig.mainViewConfiguration.entityNameProperty] as string}</Heading>
      <Divider style={{ marginBottom: '25px' }} />

      {viewConfig.entityViews
        .find((entityView) => entityTags.includes(entityView.tag))
        ?.widgetsIds.map((widgetId) => {
          const widget = viewConfig.widgets.find((widget) => widget.id === widgetId)!;
          return <WidgetView key={widget.title} widget={widget} entity={entity!} entityId={id} />;
        })}
    </Page>
  );
}
