import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@chakra-ui/react';

import { clearSearchParams } from 'src/store/actions/searchAction';
import type { SearchType } from 'src/store/reducers/searchReducer';

interface ClearSearchButtonProps {
  searchType: SearchType;
}

const ClearSearchButton = ({ searchType }: ClearSearchButtonProps) => {
  const dispatch = useDispatch();

  return (
    <Button
      display="block"
      marginLeft="auto"
      size="md"
      colorScheme="red"
      fontWeight={500}
      onClick={() => dispatch(clearSearchParams({ searchType }))}
    >
      Clear search
    </Button>
  );
};

export default ClearSearchButton;
