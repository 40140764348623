import React, { useState, useEffect } from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import BackendApi from 'src/api/BackendApi';
import WidgetStatus from './WidgetStatus';
import WidgetForm from './WidgetForm';
import LoadingSpinner from 'src/components/helpers/LoadingSpinner';
import { eventEmitter } from 'src/utils/eventEmmiter';
import type { EntityWidget } from 'src/types/Config';

export default function WidgetView({
  widget,
  entity,
  entityId
}: {
  widget: EntityWidget;
  entity: Record<string, unknown>;
  entityId: string;
}) {
  const [tickets, setTickets] = useState<Record<string, unknown>[]>([]);
  const [isFetching, setFetching] = useState(true);

  const getTickets = () =>
    BackendApi.getEntityTicketsByTag({ entityId, tag: widget.tag })
      .then((response) => setTickets(response))
      .finally(() => setFetching(false));

  useEffect(() => {
    getTickets();

    const handleEntityEvent = (data: any) => {
      if (data.entityId === entityId) {
        getTickets();
      }
    };
    eventEmitter.on('entityAttached', handleEntityEvent);
    eventEmitter.on('entityDetached', handleEntityEvent);

    return () => {
      eventEmitter.off('entityAttached', handleEntityEvent);
    };
  }, []);

  return (
    <Box
      boxShadow="base"
      rounded="lg"
      display="flex"
      flexDirection="column"
      alignItems="start"
      style={{
        marginBottom: '1rem',
        padding: '1rem'
      }}
      className="widgetWrapper"
    >
      <Heading fontSize="xl" as="h3">
        {widget.title}
      </Heading>

      {widget.description && <Text fontSize="md">{widget.description}</Text>}

      {isFetching ? (
        <LoadingSpinner />
      ) : (
        widget.statuses.map((statusConfig) => (
          <WidgetStatus
            key={statusConfig.title}
            status={statusConfig}
            tickets={tickets.filter((ticket) => ticket.status === statusConfig.status)}
            helperColumns={widget.helperColumns}
            alertsEnabled={!!widget.enableAlerts}
            entity={entity}
          />
        ))
      )}

      {widget.form && <WidgetForm entity={entity} formConfig={widget.form} />}
    </Box>
  );
}
