import { configureStore, Store } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import type { ThunkDispatch, Action } from '@reduxjs/toolkit';

import combinedReducers from './reducers';
import { socketInitMiddleware } from './middlewares/socketInitMiddleware';

export const store: Store = configureStore({
  reducer: combinedReducers,
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(socketInitMiddleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();

export default store;
