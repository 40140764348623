import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormLabel, Select, FormControl } from '@chakra-ui/react';

import { setSearchParam, clearSearchParam } from 'src/store/actions/searchAction';
import type { Tag } from 'src/types/Ticket';
import type { SearchConfigCondition } from 'src/types/Config';
import type { SearchType } from 'src/store/reducers/searchReducer';
import type { State } from 'src/types/state';

export const renderSearch = function ({
  search,
  registerIndex,
  searchType
}: {
  search: SearchConfigCondition;
  registerIndex: number;
  searchType: SearchType;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchParameter = useSelector((state: State) =>
    state.search[searchType].searchParameters.find((searchParameter) => searchParameter.key === registerIndex)
  );

  switch (search.type) {
    case 'tagsByCategory': {
      return (
        <FormControl key={search.value} style={{ marginBottom: '15px' }}>
          <FormLabel as="legend">{search.label}</FormLabel>
          <Select
            placeholder={t('buttons.select_option')}
            onChange={(event) => {
              if (event.target?.value) {
                dispatch(
                  setSearchParam({
                    searchType,
                    searchParameter: {
                      key: registerIndex,
                      type: 'tags',
                      value: event.target.value
                    }
                  })
                );
              } else {
                dispatch(clearSearchParam({ searchType, searchParameterKey: registerIndex }));
              }
            }}
            value={searchParameter?.value || ''}
          >
            {(search.options as Tag[]).map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </Select>
        </FormControl>
      );
    }

    default:
      return null;
  }
};
