import React from 'react';
import type { FC } from 'react';

import TicketListItem from './TicketListItem';
import type Ticket from './types/Ticket';

interface TicketListProps {
  tickets: Ticket[];
  selectedTicket: string | null;

  fetchTickets: () => Promise<void>;
}

const TicketList: FC<TicketListProps> = (props) => {
  return (
    <div>
      {props.tickets.map((ticket) => {
        return (
          <TicketListItem
            fetchTickets={props.fetchTickets}
            ticket={ticket}
            selectedTicket={props.selectedTicket}
            key={ticket.id}
          />
        );
      })}
    </div>
  );
};

export default TicketList;
