import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner, Text, Flex } from '@chakra-ui/react';

export default function LoadingSpinner({ text }: { text?: string }) {
  const { t } = useTranslation();

  return (
    <Flex direction="row" alignItems="center" mt={4}>
      <Spinner size="md" color="blue.500" />
      <Text ml={4} fontSize="xl">
        {text ?? t('labels.loading')}
      </Text>
    </Flex>
  );
}
