import { createReducer } from '@reduxjs/toolkit';

import { authSuccess, authFail, logOutSuccess } from '../actions/authActions';

export type AuthState = {
  auth: {
    isLoggedIn: boolean;
  };
};

const initialState: AuthState = {
  auth: { isLoggedIn: false }
};

const authReducer = createReducer(initialState.auth, (builder) => {
  builder.addCase(authSuccess, (state) => {
    state.isLoggedIn = true;
  });
  builder.addCase(authFail, (state) => {
    state.isLoggedIn = false;
  });
  builder.addCase(logOutSuccess, (state) => {
    state.isLoggedIn = false;
  });
});

export default authReducer;
