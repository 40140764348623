import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { Td } from '@chakra-ui/react';

import type { HelperColumnConfig } from 'src/types/Config';
import type { Tag } from 'src/types/Ticket';

export default function HelperColumnCell({
  helperColumnConfig,
  ticket
}: {
  helperColumnConfig: HelperColumnConfig;
  ticket: { [key: string]: any };
}) {
  const getColumnContent = () => {
    switch (helperColumnConfig.type) {
      case 'tagsByCategory': {
        const ticketTags: number[] = ticket.tags.map((tag: string) => parseInt(tag.substring(3)));
        return (helperColumnConfig.options as Tag[])
          .filter((option) => ticketTags.includes(option.id))
          .map((option) => option.name)
          .join(', ');
      }

      case 'ticketReference': {
        if (helperColumnConfig.valueType) {
          return formatColumnValue(_.get(ticket, helperColumnConfig.value), helperColumnConfig.valueType);
        }

        return _.get(ticket, helperColumnConfig.value);
      }

      default: {
        return '';
      }
    }
  };

  const formatColumnValue = (value: unknown, type: string) => {
    switch (type) {
      case 'date': {
        return moment(ticket.dueDate * 1000).format('DD.MM.YYYY HH:mm:ss');
      }
      default: {
        return value;
      }
    }
  };

  return <Td>{getColumnContent()}</Td>;
}
