import { t } from 'i18next';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { AUTH_SUCCESS, AUTH_FAIL, LOGOUT } from './index';
import BackendApi from 'src/api/BackendApi';
import { showToast } from './toastActions';
import SocketInstance from 'src/socket/instance';
import type { LoginResult } from 'src/types/Auth';

export const authSuccess = createAction(AUTH_SUCCESS);
export const authFail = createAction(AUTH_FAIL);
export const logOutSuccess = createAction(LOGOUT);

type LoginType = 'eid' | 'desk';

export const logIn = createAsyncThunk<
  { status: 'success' | 'failed' },
  { type: LoginType; originalUrl?: string; username?: string; password?: string },
  { rejectValue: Error }
>('logIn', async ({ originalUrl, type, username, password }, { dispatch }) => {
  try {
    let loginResult: LoginResult | undefined = undefined;

    switch (type) {
      case 'eid':
        loginResult = await BackendApi.eidLogin(originalUrl!);
        break;
      case 'desk':
        loginResult = await BackendApi.deskLogin({ username: username!, password: password! });
      default:
        break;
    }

    if (loginResult?.status === 'success' && loginResult?.redirectUrl) {
      window.location = loginResult?.redirectUrl as unknown as Location;
    }

    return { status: loginResult?.status as 'success' | 'failed' };
  } catch (error) {
    dispatch(authFail());
    return { status: 'failed' };
  }
});

export const logOut = createAsyncThunk<
  { status: 'success' | 'failed' },
  { type: 'manual' | 'automatic'; enableToast?: boolean },
  { rejectValue: Error }
>('logOut', async ({ type, enableToast = true }, { dispatch }) => {
  try {
    await BackendApi.logout();

    if (enableToast) {
      switch (type) {
        case 'manual':
          dispatch(
            showToast({
              title: t('toasts.logout_success_title'),
              description: t('toasts.logout_success_desc'),
              status: 'info',
              position: 'bottom-right'
            })
          );
          break;
        case 'automatic':
          dispatch(
            showToast({
              title: t('toasts.session_expired_title'),
              description: t('toasts.session_expired_title_desc'),
              status: 'info',
              position: 'bottom-right'
            })
          );
          break;
        default:
          break;
      }
    }

    dispatch(logOutSuccess());
    SocketInstance.disconnect();
    localStorage.clear();

    return { status: 'success' };
  } catch (error) {
    dispatch(authFail());
    return { status: 'failed' };
  }
});
