import moment from 'moment';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { Divider, Heading, useToast, TableContainer, Table, Thead, Tr, Th, Td, Tbody, Text } from '@chakra-ui/react';
import type { RouteComponentProps } from 'react-router';

import Page from '../Page/Page';
import BackendApi from 'src/api/BackendApi';
import { renderSearch } from '../helpers/searchResult';
import LoadingSpinner from '../helpers/LoadingSpinner';
import ClearSearchButton from '../helpers/ClearSearchButton';
import { setSearchResult } from 'src/store/actions/searchAction';
import type { State } from 'src/types/state';
import type { BiggestValueColumn, ClosestValueColumn, SummaryView } from 'src/types/Config';

interface SummaryViewProps extends RouteComponentProps {}

const SummaryViewComponent = ({ history }: SummaryViewProps) => {
  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const summaryView = useSelector((state: State) => state.viewConfig?.summaryView) as SummaryView;
  const searchParameters = useSelector((state: State) => state.search['summaryView'].searchParameters);
  const searchResult = useSelector((state: State) => state.search['summaryView'].searchResult) as {
    summary: (string | number)[];
    info: Record<string, unknown>;
  }[];

  const [isSearching, setSearching] = useState(false);

  useEffect(() => {
    if (!!searchParameters.length) {
      setSearching(true);
      BackendApi.getSummary({
        parameters: searchParameters.map(({ type, value }) => ({ type, value })),
        taskType: summaryView.taskType,
        entityTypes: summaryView.entityTypes
      })
        .then((result) => {
          dispatch(
            setSearchResult({
              searchType: 'summaryView',
              searchResult: result.data
            })
          );
        })
        .catch(() =>
          toast({
            title: t('toasts.search_failed'),
            status: 'error',
            duration: 3000,
            isClosable: true
          })
        )
        .finally(() => {
          setSearching(false);
        });
    }
  }, [searchParameters]);

  const renderSummaryTable = () => {
    if (isSearching) {
      return <LoadingSpinner />;
    }

    if (searchResult.length) {
      return (
        <TableContainer style={{ border: '1px solid #e2e8f0', borderRadius: '12px', marginTop: '10px', width: '100%' }}>
          <Table size="sm" variant="striped">
            <Thead>
              <Tr>
                {summaryView.columns.map((column) => (
                  <Th
                    key={column.name}
                    style={{
                      whiteSpace: 'normal',
                      fontSize: '0.7rem'
                    }}
                  >
                    {column.name}
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody>
              {searchResult.map((resultRow) => (
                <Tr
                  key={resultRow.info._id as string}
                  onClick={() => history.push(`/entity/${resultRow.info._id}`)}
                  style={{ cursor: 'pointer' }}
                >
                  {resultRow.summary.map((value, index) => {
                    if ((summaryView.columns[index] as BiggestValueColumn | ClosestValueColumn).valueType === 'time') {
                      return (
                        <Td>
                          <Text isTruncated>
                            {!!value ? moment((value as number) * 1000).format('DD.MM.YYYY') : '--'}
                          </Text>
                        </Td>
                      );
                    }

                    return (
                      <Td>
                        <Text isTruncated maxWidth={175}>
                          {!!value ? value : '--'}
                        </Text>
                      </Td>
                    );
                  })}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      );
    }

    return null;
  };

  return (
    <Page>
      <Heading fontSize="3xl">{summaryView.title}</Heading>
      <Divider style={{ marginBottom: '25px' }} />

      {summaryView.search.map((search, index) =>
        renderSearch({
          search,
          registerIndex: index,
          searchType: 'summaryView'
        })
      )}

      {!!searchParameters.length && <ClearSearchButton searchType="summaryView" />}

      {renderSummaryTable()}
    </Page>
  );
};

export default withRouter(SummaryViewComponent);
