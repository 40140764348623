import { createAction } from '@reduxjs/toolkit';

import { SET_SEARCH_PARAM, CLEAR_SEARCH_PARAM, SET_SEARCH_RESULT, CLEAR_SEARCH_PARAMS } from './index';
import type { SearchParameter, SearchType } from '../reducers/searchReducer';

export const setSearchParam = createAction<{
  searchType: SearchType;
  searchParameter: SearchParameter;
}>(SET_SEARCH_PARAM);
export const clearSearchParam = createAction<{
  searchType: SearchType;
  searchParameterKey: number;
}>(CLEAR_SEARCH_PARAM);
export const setSearchResult = createAction<{
  searchType: SearchType;
  searchResult: Record<string, unknown>[];
}>(SET_SEARCH_RESULT);
export const clearSearchParams = createAction<{ searchType: SearchType }>(CLEAR_SEARCH_PARAMS);
