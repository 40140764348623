import { connectRouter } from 'connected-react-router';
import { createBrowserHistory as createHistory } from 'history';
import { combineReducers } from 'redux';

import authReducer from './authReducer';
import { viewConfigReducer, configReducer } from './configReducer';
import frameReducer from './frameReducer';
import { personalDataReducer } from './personalDataReducer';
import searchReducer from './searchReducer';
import toastReducer from './toastReducer';

export const history = createHistory();
const router = connectRouter(history);

export default combineReducers({
  router: router,
  auth: authReducer,
  viewConfig: viewConfigReducer,
  config: configReducer,
  toast: toastReducer,
  frame: frameReducer,
  search: searchReducer,
  personalData: personalDataReducer
});
