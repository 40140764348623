import { t } from 'i18next';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from './toastActions';
import { FETCH_USER_PERSONAL_DATA_SUCCESS, FETCH_USER_PERSONAL_DATA_FAIL } from './index';
import BackendApi from 'src/api/BackendApi';
import type { PersonalData } from 'src/types/Profile';

export const fetchUserPersonalDataSuccess = createAction<PersonalData>(FETCH_USER_PERSONAL_DATA_SUCCESS);
export const fetchUserPersonalDataFail = createAction(FETCH_USER_PERSONAL_DATA_FAIL);

export const fetchPersonalData = createAsyncThunk<
  { personalData: PersonalData | undefined },
  undefined,
  { rejectValue: Error }
>('fetchPersonalData', async (argument, { dispatch }) => {
  try {
    const personalData = await BackendApi.getPersonalData();

    dispatch(fetchUserPersonalDataSuccess(personalData));
    return { personalData };
  } catch (error) {
    dispatch(fetchUserPersonalDataFail());
    dispatch(
      showToast({
        title: 'Error',
        description: t('toasts.personaldata_fetch_failed'),
        status: 'error',
        position: 'bottom-right'
      })
    );
    return { personalData: undefined };
  }
});

export const setLanguage = createAsyncThunk<void, { langId: string }, { rejectValue: Error }>(
  'setLanguage',
  async ({ langId }, { dispatch }) => {
    try {
      await BackendApi.changeUserLanguage(langId);

      dispatch(fetchPersonalData());
    } catch (error) {
      dispatch(fetchUserPersonalDataFail());
      throw error;
    }
  }
);
