import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Alert,
  AlertIcon,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Spinner,
  Text,
  useToast
} from '@chakra-ui/react';
import type { RouteComponentProps } from 'react-router';

import Page from '../Page/Page';
import BackendApi from 'src/api/BackendApi';
import { renderSearch } from '../helpers/searchResult';
import { setSearchResult } from 'src/store/actions/searchAction';
import ClearSearchButton from '../helpers/ClearSearchButton';
import type { State } from 'src/types/state';
import type { MainViewConfiguration } from 'src/types/Config';

interface MainViewProps extends RouteComponentProps {}

const MainView = ({ history }: MainViewProps) => {
  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const mainViewConfiguration = useSelector(
    (state: State) => state.viewConfig?.mainViewConfiguration
  ) as MainViewConfiguration;
  const searchParameters = useSelector((state: State) => state.search['mainView'].searchParameters);
  const searchResult = useSelector((state: State) => state.search['mainView'].searchResult);

  const [isSearching, setSearching] = useState(false);

  useEffect(() => {
    if (!!searchParameters.length) {
      setSearching(true);
      BackendApi.searchEntities({
        parameters: searchParameters.map(({ type, value }) => ({ type, value })),
        taskType: mainViewConfiguration.taskType,
        entityTypes: mainViewConfiguration.entityTypes
      })
        .then((result) => {
          dispatch(
            setSearchResult({
              searchType: 'mainView',
              searchResult: result.data
            })
          );
        })
        .catch(() =>
          toast({
            title: t('toasts.search_failed'),
            status: 'error',
            duration: 3000,
            isClosable: true
          })
        )
        .finally(() => {
          setSearching(false);
        });
    }
  }, [searchParameters]);

  return (
    <Page>
      <Heading fontSize="3xl">{mainViewConfiguration.title}</Heading>
      <Divider style={{ marginBottom: '25px' }} />

      {mainViewConfiguration.search.map((search, index) =>
        renderSearch({
          search,
          registerIndex: index,
          searchType: 'mainView'
        })
      )}

      {!!searchParameters.length && <ClearSearchButton searchType="mainView" />}

      {!!searchResult.length && (
        <FormControl style={{ marginBottom: '15px' }}>
          <FormLabel as="legend">{mainViewConfiguration.resultLabel}</FormLabel>
          <Select
            disabled={isSearching}
            placeholder={t('buttons.select_option')}
            onChange={(event) => {
              history.push(`/entity/${event.target.value}`);
            }}
          >
            {searchResult.map((result) => (
              <option key={result._id as string} value={result._id as string}>
                {result[mainViewConfiguration.entityNameProperty] as string}
              </option>
            ))}
          </Select>
        </FormControl>
      )}

      {!searchResult.length && !isSearching && !!searchParameters.length && (
        <Alert status="warning" borderRadius={8}>
          <AlertIcon />
          {t('labels.no_results')}
        </Alert>
      )}

      {isSearching && (
        <>
          <Flex alignItems="center">
            <Text fontSize="xl">{t('labels.searching')}</Text>
            <Spinner ml={2} size="md" color="blue.500" />
          </Flex>
        </>
      )}
    </Page>
  );
};

export default withRouter(MainView);
