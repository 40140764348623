import React from 'react';
import { FiLogOut } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, Spacer } from '@chakra-ui/react';

import LanguageSelect from './LanguageSelect';
import EIDSessionCounter from './EIDSessionCounter';
import { useAppThunkDispatch } from 'src/store/store';
import { logOut } from 'src/store/actions/authActions';

export default () => {
  const { t } = useTranslation();
  const dispatch = useAppThunkDispatch();

  return (
    <Flex bg="gray.100" p={4} className="topbar">
      <Box>{/* Navigation links go here */}</Box>
      <Spacer />

      <EIDSessionCounter />
      <LanguageSelect />
      <Box>
        <Button
          size="md"
          leftIcon={<Box as={FiLogOut} size="16px" color="white" />}
          colorScheme="teal"
          fontWeight={500}
          className="logoutButton"
          onClick={() => dispatch(logOut({ type: 'manual' }))}
        >
          {t('buttons.logout')}
        </Button>
      </Box>
    </Flex>
  );
};
