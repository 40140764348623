import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import type { UseToastOptions } from '@chakra-ui/react';

import { RootState } from 'src/store/store';

const ToastComponent = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const toastData = useSelector<RootState, UseToastOptions | null>((state) => state.toast);

  useEffect(() => {
    if (toastData) {
      toast({
        ...toastData,
        duration: 3000,
        isClosable: true
      });

      dispatch({ type: 'SHOW_TOAST', payload: null });
    }
  }, [toastData]);

  return null;
};

export default ToastComponent;
