import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { FETCH_VIEW_CONFIG_SUCCESS, FETCH_VIEW_CONFIG_FAIL, CONFIG_SUCCESS, CONFIG_FAIL } from './index';
import BackendApi from 'src/api/BackendApi';
import { logOut } from './authActions';
import type { PortalConfig, ViewConfig } from 'src/types/Config';

export const viewConfigSuccess = createAction<ViewConfig>(FETCH_VIEW_CONFIG_SUCCESS);
export const viewConfigFail = createAction(FETCH_VIEW_CONFIG_FAIL);
export const configSuccess = createAction<PortalConfig>(CONFIG_SUCCESS);
export const configFail = createAction(CONFIG_FAIL);

export const fetchViewConfig = createAsyncThunk<{ status: 'success' | 'failed' }, undefined, { rejectValue: Error }>(
  'fetchViewConfig',
  async (argument, { dispatch }) => {
    try {
      const config = await BackendApi.getViewConfig();

      dispatch(viewConfigSuccess(config));
      return { status: 'success' };
    } catch (error) {
      dispatch(viewConfigFail());
      dispatch(logOut({ type: 'automatic' }));
      return { status: 'failed' };
    }
  }
);

export const fetchConfig = createAsyncThunk<{ status: 'success' | 'failed' }, undefined, { rejectValue: Error }>(
  'fetchConfig',
  async (argument, { dispatch }) => {
    try {
      const config = await BackendApi.getConfig();

      dispatch(configSuccess(config));
      return { status: 'success' };
    } catch (error) {
      dispatch(configFail());
      return { status: 'failed' };
    }
  }
);
