import { createReducer } from '@reduxjs/toolkit';

import { viewConfigSuccess, viewConfigFail, configSuccess, configFail } from '../actions/configActions';
import type { ViewConfig, PortalConfig } from 'src/types/Config';

export type ConfigState = {
  viewConfig: ViewConfig | null | undefined;
  config: PortalConfig | null | undefined;
};

const initialState: ConfigState = {
  viewConfig: null,
  config: null
};

const viewConfigReducer = createReducer(initialState.viewConfig, (builder) => {
  builder.addCase(viewConfigSuccess, (_, action) => {
    return action.payload;
  });

  builder.addCase(viewConfigFail, () => {
    return undefined;
  });
});

const configReducer = createReducer(initialState.config, (builder) => {
  builder.addCase(configSuccess, (_, action) => {
    return action.payload;
  });

  builder.addCase(configFail, () => {
    return undefined;
  });
});

export { viewConfigReducer, configReducer };
