import { createReducer } from '@reduxjs/toolkit';

import { fetchUserPersonalDataSuccess, fetchUserPersonalDataFail } from '../actions/personalDataActions';
import type { PersonalData } from 'src/types/Profile';

export type PersonalDataState = {
  personalData: PersonalData | null | undefined;
};

const initialState: PersonalDataState = {
  personalData: null
};

const personalDataReducer = createReducer(initialState.personalData, (builder) => {
  builder.addCase(fetchUserPersonalDataSuccess, (_, action) => {
    return action.payload;
  });

  builder.addCase(fetchUserPersonalDataFail, () => {
    return undefined;
  });
});

export { personalDataReducer };
