import { IRenderer } from 'fela';
import React, { Component } from 'react';
import { RendererProvider } from 'react-fela';

import { getFelaRenderer } from './get-fela-renderer';

const fallbackRenderer = getFelaRenderer();

export class FelaProvider extends Component<{ renderer?: IRenderer; children: React.ReactNode }> {
  render() {
    const renderer = this.props.renderer ?? fallbackRenderer;
    return <RendererProvider renderer={renderer}>{this.props.children}</RendererProvider>;
  }
}
