import { createReducer } from '@reduxjs/toolkit';

import { openFrame, closeFrame } from '../actions/frameActions';

export type FrameState = {
  frame: {
    isOpen: boolean;
    contentId: string | undefined;
  };
};

const initialState: FrameState = {
  frame: {
    isOpen: false,
    contentId: undefined
  }
};

const authReducer = createReducer(initialState.frame, (builder) => {
  builder.addCase(openFrame, (state, action) => {
    state.isOpen = true;
    state.contentId = action.payload.contentId;
  });
  builder.addCase(closeFrame, (state) => {
    state.isOpen = false;
    state.contentId = undefined;
  });
});

export default authReducer;
