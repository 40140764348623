import { extendTheme } from '@chakra-ui/react';

const customTheme = extendTheme({
  colors: {
    accordionColor: '#c99c61',
    mainBackgroundColor: ' #3f342b',
    formBackgroundColor: '#eeebea',
    brand: {
      100: '#f7fafc',
      // ...
      900: '#1a202c'
    }
  },
  fonts: {}
});

export default customTheme;
