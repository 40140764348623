import { createReducer } from '@reduxjs/toolkit';
import type { UseToastOptions } from '@chakra-ui/react';

import { showToast } from '../actions/toastActions';

export type ToastState = {
  toast: UseToastOptions | null;
};

const initialState: ToastState = {
  toast: null
};

const authReducer = createReducer(initialState.toast, (builder) => {
  builder.addCase(showToast, (state, action) => {
    return action.payload;
  });
});

export default authReducer;
