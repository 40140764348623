export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const LOGOUT = 'LOGOUT';

export const FETCH_VIEW_CONFIG_SUCCESS = 'FETCH_VIEW_CONFIG_SUCCESS';
export const FETCH_VIEW_CONFIG_FAIL = 'FETCH_VIEW_CONFIG_FAIL';

export const CONFIG_SUCCESS = 'CONFIG_SUCCESS';
export const CONFIG_FAIL = 'CONFIG_FAIL';

export const OPEN_FRAME = 'OPEN_FRAME';
export const CLOSE_FRAME = 'CLOSE_FRAME';

export const SHOW_TOAST = 'SHOW_TOAST';

export const SET_SEARCH_PARAM = 'SET_SEARCH_PARAM';
export const CLEAR_SEARCH_PARAM = 'CLEAR_SEARCH_PARAM';
export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT';
export const CLEAR_SEARCH_PARAMS = 'CLEAR_SEARCH_PARAMS';

export const FETCH_USER_PERSONAL_DATA_SUCCESS = 'FETCH_USER_PERSONAL_DATA_SUCCESS';
export const FETCH_USER_PERSONAL_DATA_FAIL = 'FETCH_USER_PERSONAL_DATA_FAIL';
