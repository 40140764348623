import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './i18n';

import * as serviceWorker from './serviceWorker';
import App from './App';
import store from './store/store';
import ApiConfig from './api/ApiConfig';
import BackendApi from './api/BackendApi';
import FeatureFlags from './api/FeatureFlags';

(async () => {
  await ApiConfig.fetchConfig();
  await FeatureFlags.getFeatureFlags();
  BackendApi.init();

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
