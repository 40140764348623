import { createReducer } from '@reduxjs/toolkit';

import { setSearchParam, clearSearchParam, setSearchResult, clearSearchParams } from '../actions/searchAction';

export type SearchType = 'mainView' | 'summaryView';

export interface SearchParameter {
  key: number;
  type: string;
  value: string;
}

export type SearchState = {
  search: {
    [key in SearchType]: {
      searchParameters: SearchParameter[];
      searchResult: Record<string, unknown>[];
    };
  };
};

const initialState: SearchState = {
  search: {
    mainView: {
      searchParameters: [],
      searchResult: []
    },
    summaryView: {
      searchParameters: [],
      searchResult: []
    }
  }
};

const searchReducer = createReducer(initialState.search, (builder) => {
  builder.addCase(setSearchParam, (state, action) => {
    state[action.payload.searchType].searchParameters = [
      ...state[action.payload.searchType].searchParameters.filter(
        (searchParamater) => searchParamater.key !== action.payload.searchParameter.key
      ),
      action.payload.searchParameter
    ];
  });
  builder.addCase(clearSearchParam, (state, action) => {
    const newSearchParameters = [
      ...state[action.payload.searchType].searchParameters.filter(
        (searchParamater) => searchParamater.key !== action.payload.searchParameterKey
      )
    ];
    state[action.payload.searchType].searchParameters = newSearchParameters;

    if (newSearchParameters.length === 0) {
      state[action.payload.searchType].searchResult = [];
    }
  });
  builder.addCase(setSearchResult, (state, action) => {
    state[action.payload.searchType].searchResult = action.payload.searchResult;
  });
  builder.addCase(clearSearchParams, (state, action) => {
    state[action.payload.searchType] = { searchParameters: [], searchResult: [] };
  });
});

export default searchReducer;
